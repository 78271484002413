export function formatNumber(phone) {
  const phoneFormat = phone
    .replace("-", "")
    .replace("(", "")
    .replace(")", "")
    .replace(" ", "")

  return phoneFormat
}

export function extractNumber(phone) {
  const phone_country_code = 55
  const phone_number = parseInt(phone)

  return {
    phone_country_code,
    phone_number,
  }
}

export const formatAndExtractNumber = number => extractNumber(formatNumber(number))
