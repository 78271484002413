import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import SigninSms from "../sections/signinSms"

function CodeInvite({ location }) {
  return (
    <Layout>
      
      <SEO title="Cadastro" />
      <SigninSms deal={location.state?.deal} phone={location.state?.phone} name={location.state?.name || null} />
    </Layout>
  );
}

export default CodeInvite;