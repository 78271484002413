const mutations = {
  CREATE_ACCOUNT: `
    mutation ($input: createAccountInput!) {
      createAccount(input: $input) {
        id
        phone_number
        phone_country_code
        status
        token
      }
    }
   `,
  AUTHENTICATE: `
    mutation ($input: authenticateInput) {
      authenticate(input: $input) {
        id
      }
    }
  `,

  
  RECOVER_PASSWORD_EMAIL: `
    mutation ($input: authenticateInput!) {
      recoverPasswordEmail(input: $input) {
        id
      }
    }
  `,


  VALIDATE: `
  mutation ($input: validateCodeInput) {
    validateCode(input: $input) {
      token
    }
  }`,
  AUTHENTICATE_CPF: `
  mutation ($input: authenticateCpfInput) {
    authenticateCpf(input: $input) {
      token
    }
  }`,
  CREATE_PERSON: `
    mutation ($input: createPersonInput!) {
      createPerson(input: $input) {
        id
        name
        occupation_area
      }
    }
  `,
  CREATE_PERSON_DOCUMENT: `
    mutation ($input: createPersonInput!) {
      createPersonDocument(input: $input) {
        id
        owner{
          name
        }
      }
    }
  `,
  CREATE_DEAL: `
    mutation ($input: createDealInput!) {
      createDeal(input: $input) {
        id
        value
        split
      }
    }
  `,
  CREATE_PARTICIPANT: `
    mutation ($input: createParticipantInput!) {
      createParticipant(input: $input) {
        deal
        participant {
          person {
            id
            name
          }
        }
      }
    }
  `,
  DELETE_PARTICIPANT: `
    mutation ($input: deleteParticipantInput) {
      deleteParticipant(input: $input) {
        id
      }
    }
  `,
  CREATE_CODE_PARTICIPANT: `
    mutation($input: createCodeParticipantInput) {
      createCodeParticipant(input: $input) {
        deal
        participant {
          person {
            id
            name
          }
        }
      }
    }
  `,
  ACCEPT_INVITE: `
    mutation($input: acceptInviteInput) {
      acceptInvite(input: $input) {
        id
        deal
        value
        status_invite
      }
    }
  `,
  CREATE_ACCOUNT_PASSWORD: `
    mutation ($input: createAccountPasswordInput) {
      createAccountPassword(input: $input) {
        id
        token
      }
    }
  `,
  CONSULTATE_SERASA: `
    mutation ($input: consultationDocumentsInput) {
      consultateSerasa(input: $input) {
        payload
      }
    }
  `
}

export default mutations
