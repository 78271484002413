import React, { useState } from "react"
import { navigate } from "gatsby"
import Photo from "../../images/consultor.png"
import client from "../../service/graphql"
import mutations from "../../service/mutations"
import ReactCodeInput from 'react-verification-code-input';

import {
  Container,
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Input,
  Button,
  Loading,
  ContainerError,
  ErrorText
} from "./styles"
import { Body, Buttons } from "../../steps/shared-styles"
import HeaderProgress from "../../components/HeaderProgress"
import { formatNumber, extractNumber } from "../../utils"

const SigninSms = ({ deal, phone, name = null }) => {
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleValidate = async (code) => {
    try {

      const { phone_country_code, phone_number } = extractNumber(phone);
  
      const { validateCode } = await client.request(mutations.VALIDATE, {
        input: {
          code,
          phone_country_code,
          phone_number,
        },
      });
  
      const { token } = validateCode;
      
      client.setHeader("authorization", `Bearer ${token}`);
      
      setValid(true);
      setError('');
    } catch (errors) {
      setError('Erro ao reenviar código.');
    }
  };


  async function submit() {
    try {
      setLoading(true);
      if(name) {
        await client.request(mutations.CREATE_PERSON, {
          input: {
            name: name
          },
        });
      }
      
      navigate('/finish-invite', { state: { deal } })
    } catch (error) {
      console.log(error)      
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <HeaderProgress description="Sobre você" percent={70} />
      <Container>
          <Section>
            <Body>
              <Header>
                <Profile>
                  <User src={Photo} />
                </Profile>
                <Label><p>Digite o código que você acabou de receber por e-mail</p></Label>
              </Header>
              <Form>
                <ReactCodeInput 
                  fields={4}
                  onComplete={e => handleValidate(e)}
                />

                {error.length > 0 && (
                  <ContainerError>
                    <ErrorText>{error}</ErrorText>
                  </ContainerError>
                )}
                {loading && <Loading />}
              </Form>
            </Body>
            <Buttons>
              <Button onClick={submit} disabled={!valid}>
                Próximo
              </Button>
            </Buttons>
          </Section>
      </Container>
    </div>
  )
}

export default SigninSms
